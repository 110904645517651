var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "buttons", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Buttons", link: "components/buttons" }
      }),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "pt-0" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("base-subheading", [
                        _c("h5", { staticClass: "font-weight-light" }, [
                          _vm._v(
                            "\n              Pick your Color\n            "
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        _vm._l(_vm.colors, function(color, i) {
                          return _c(
                            "v-btn",
                            {
                              key: i,
                              staticClass: "ma-1",
                              attrs: {
                                color: color === "default" ? undefined : color
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(color) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pt-0", attrs: { cols: "12", md: "6" } },
                    [
                      _c("base-subheading", [
                        _c("h5", { staticClass: "font-weight-light" }, [
                          _vm._v(
                            "\n              Buttons with Label\n            "
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        [
                          _vm._l(["left", "right"], function(dir, i) {
                            return _c(
                              "v-btn",
                              { key: i, staticClass: "ma-1" },
                              [
                                _c("v-icon", {
                                  class: dir === "right" && "order-last",
                                  attrs: {
                                    left: dir === "left",
                                    right: dir === "right"
                                  },
                                  domProps: {
                                    textContent: _vm._s(_vm.icons[dir])
                                  }
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(dir) +
                                    "\n            "
                                )
                              ],
                              1
                            )
                          }),
                          _vm._l(_vm.colors.slice(2), function(color, i) {
                            return _c(
                              "v-btn",
                              {
                                key: "btn-" + i,
                                staticClass: "ma-1",
                                attrs: {
                                  color: color === "default" ? undefined : color
                                }
                              },
                              [
                                _c("v-icon", {
                                  attrs: { left: "" },
                                  domProps: {
                                    textContent: _vm._s(_vm.icons[color])
                                  }
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(color) +
                                    "\n            "
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("base-subheading", [
                        _c("h5", { staticClass: "font-weight-light" }, [
                          _vm._v("\n              Pick your Size\n            ")
                        ])
                      ]),
                      _vm._l(3, function(n) {
                        return _c(
                          "v-btn",
                          {
                            key: n,
                            staticClass: "ma-1",
                            attrs: {
                              small: n === 1,
                              large: n === 3,
                              color: "secondary"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  n === 1
                                    ? "small"
                                    : n === 2
                                    ? "regular"
                                    : "large"
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("base-subheading", [
                        _c("h5", { staticClass: "font-weight-light" }, [
                          _vm._v(
                            "\n              Pick your Style\n            "
                          )
                        ])
                      ]),
                      _c(
                        "v-btn",
                        { staticClass: "ma-1", attrs: { color: "secondary" } },
                        [_vm._v("\n            Default\n          ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: { color: "secondary", rounded: "" }
                        },
                        [_vm._v("\n            Rounded\n          ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: { color: "secondary", rounded: "" }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("\n              mdi-heart\n            ")
                          ]),
                          _vm._v("\n            With Icon\n          ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: { color: "secondary", fab: "", small: "" }
                        },
                        [
                          _c("v-icon", [
                            _vm._v("\n              mdi-heart\n            ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: { color: "secondary", text: "" }
                        },
                        [_vm._v("\n            Simple\n          ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("base-subheading", [
                        _c("h5", { staticClass: "font-weight-light" }, [
                          _vm._v("\n              Pagination\n            ")
                        ])
                      ]),
                      _c("v-pagination", {
                        staticClass: "justify-start",
                        attrs: { length: 5, value: 1, circle: "" }
                      }),
                      _c("v-pagination", {
                        staticClass: "justify-start",
                        attrs: {
                          length: 3,
                          value: 2,
                          circle: "",
                          "next-icon": "mdi-menu-right",
                          "prev-icon": "mdi-menu-left"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("base-subheading", [
                        _c("h5", { staticClass: "font-weight-light" }, [
                          _vm._v("\n              Button Group\n            ")
                        ])
                      ]),
                      _c(
                        "v-sheet",
                        [
                          _c(
                            "v-btn-toggle",
                            { attrs: { color: "teal" } },
                            _vm._l(["left", "middle", "right"], function(dir) {
                              return _c("v-btn", { key: dir }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(dir) +
                                    "\n              "
                                )
                              ])
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "my-6" }),
                      _c(
                        "v-btn-toggle",
                        { attrs: { color: "teal", rounded: "" } },
                        _vm._l(4, function(n) {
                          return _c("v-btn", { key: n }, [
                            _vm._v(
                              "\n              " + _vm._s(n) + "\n            "
                            )
                          ])
                        }),
                        1
                      ),
                      _c("div", { staticClass: "my-2" }),
                      _c(
                        "v-btn-toggle",
                        { attrs: { color: "teal", rounded: "" } },
                        _vm._l(3, function(n) {
                          return _c("v-btn", { key: n }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(n + 4) +
                                "\n            "
                            )
                          ])
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.social, function(s, i) {
                    return [
                      _c(
                        "v-col",
                        { key: i, staticClass: "py-1", attrs: { cols: "12" } },
                        [
                          i === 0
                            ? _c("base-subheading", { key: "heading-" + i }, [
                                _c("h5", { staticClass: "font-weight-light" }, [
                                  _vm._v(
                                    "\n                Social buttons\n              "
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto", md: "4", sm: "5" } },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { color: s.color, dark: "" } },
                                    [
                                      _c("v-icon", {
                                        attrs: { left: "" },
                                        domProps: {
                                          textContent: _vm._s(s.icon)
                                        }
                                      }),
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(s.text) +
                                          "\n                "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto", md: "1", sm: "1" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: s.color,
                                        dark: "",
                                        "min-width": "0",
                                        "max-width": "41"
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        domProps: {
                                          textContent: _vm._s(s.icon)
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto", md: "1", sm: "1" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: s.color,
                                        dark: "",
                                        fab: "",
                                        "min-width": "0",
                                        small: ""
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        domProps: {
                                          textContent: _vm._s(s.icon)
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto", md: "1", sm: "1" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: s.color,
                                        dark: "",
                                        icon: "",
                                        "min-width": "0"
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        attrs: { color: s.color },
                                        domProps: {
                                          textContent: _vm._s(s.icon)
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto", md: "3", sm: "4" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: s.color,
                                        dark: "",
                                        text: ""
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        attrs: { left: "", color: s.color },
                                        domProps: {
                                          textContent: _vm._s(s.icon)
                                        }
                                      }),
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(s.text) +
                                          "\n                "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }